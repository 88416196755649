<template>
  <div>
    <div>
      <p class="is-title">Illustration</p>
      <div class="section-illustration">
        <img src="@/assets/illustration/general.svg" alt="" >
        <img src="@/assets/illustration/notify.svg" alt="" >
        <img src="@/assets/illustration/ticket.svg" alt="" >

        <img src="@/assets/illustration/empty-folder.svg" alt="" >
        <img src="@/assets/illustration/connection-lost.svg" alt="" >
        <img src="@/assets/illustration/rating.svg" alt="" >

        <img src="@/assets/illustration/xls-file.svg" alt="" >
        <img src="@/assets/illustration/coupon.svg" alt="" >
        <img src="@/assets/illustration/lock-room.svg" alt="" >

        <img src="@/assets/illustration/file-not-found.svg" alt="" >
        <img src="@/assets/illustration/product.svg" alt="" >
        <img src="@/assets/illustration/employee.svg" alt="" >

        <img src="@/assets/illustration/search-recondialition.svg" alt="" >
        <img src="@/assets/illustration/booking.svg" alt="" >
        <img src="@/assets/illustration/dd.svg" alt="" >

        <img src="@/assets/illustration/message.svg" alt="" >
        <img src="@/assets/illustration/dashboard.svg" alt="" >
        <img src="@/assets/illustration/coming-soon.svg" alt="" >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'illustration',
  components : {
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.is-title {
  font-weight: 600;
  font-size: 25px;
  color: #000000;
}
.section-illustration {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
}

</style>
